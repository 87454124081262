import React, { useState, useEffect } from "react";
import "./LandingPage.css";
import thumb_provingGrounds from "./images/Content/ProvingGrounds.jpg";
import promo_provingGrounds from "./images/Content/ch1titleNowAvailable.jpg";
import promo_patreon from "./images/Content/patreonNowAvailable.jpg";

type ContentType = "Game" | "Video" | "Audio" | "Image" | "Comic";

interface ContentItem {
  id: number;
  title: string;
  type: ContentType;
  thumbnail: string;
  link: string;
}

const promotions = [
  {
    id: 1,
    image: promo_provingGrounds,
    link: "https://www.youtube.com/watch?v=DRQj_X30V0Q",
  },
  {
    id: 2,
    image: promo_patreon,
    link: "https://patreon.com/MorningStarOfficial?utm_medium=unknown&utm_source=join_link&utm_campaign=creatorshare_creator&utm_content=copyLink",
  },
];

const contentItems: ContentItem[] = [
  {
    id: 1,
    title: "Proving Grounds",
    type: "Video",
    thumbnail: thumb_provingGrounds,
    link: "https://www.youtube.com/watch?v=DRQj_X30V0Q",
  },
];

function LandingPage(): JSX.Element {
  const [activePromo, setActivePromo] = useState<number>(0);
  const [fade, setFade] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState<ContentType | "All">("All");

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // Start fade-out effect

      setTimeout(() => {
        setActivePromo((prev) => (prev + 1) % promotions.length);
        setFade(true); // Start fade-in effect
      }, 500); // Matches fade duration
    }, 10000); // Change every 10 seconds

    return () => clearInterval(interval);
  }, []);

  const filteredContent = selectedFilter === "All"
    ? contentItems
    : contentItems.filter((item) => item.type === selectedFilter);

  return (
    <div className="landing-page">
      {/* Promotion Panel (Only Display If Promotions Exist) */}
      {promotions.length > 0 && (
        <div className="promotion-panel">
          <a href={promotions[activePromo].link} target="_blank" rel="noopener noreferrer">
            <img src={promotions[activePromo].image} alt="Promotion" className={`promo-image ${fade ? "fade-in" : "fade-out"}`} />
          </a>
        </div>
      )}

      {/* Title Panel */}
      <div className="title-panel">
        <h1>Morning Star</h1>
      </div>

      {/* Content Filter Panel */}
      <div className="filter-panel">
        <button onClick={() => setSelectedFilter("All")}>All</button>
        <button onClick={() => setSelectedFilter("Game")}>Games</button>
        <button onClick={() => setSelectedFilter("Video")}>Videos</button>
        <button onClick={() => setSelectedFilter("Audio")}>Audio</button>
        <button onClick={() => setSelectedFilter("Image")}>Images</button>
        <button onClick={() => setSelectedFilter("Comic")}>Comics</button>
      </div>

      {/* Content Panel (Only Render if Content Exists) */}
      {filteredContent.length > 0 && (
        <div className="content-panel">
          {filteredContent.map((item) => (
            <a key={item.id} href={item.link} target="_blank" rel="noopener noreferrer" className="content-item">
              <img src={item.thumbnail} alt={item.title} className="content-thumbnail" />
              <p>{item.title}</p>
            </a>
          ))}
        </div>
      )}
    </div>
  );
}

export default LandingPage;
