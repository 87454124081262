import React from 'react';
import './App.css';

const About: React.FC = () => {
  return (
    <div className='main-content'>

<div className="spacer"></div>


<h2>Welcome to Studio Fantasy</h2>
        <p>Unleashing global creativity, fostering artistic careers, and celebrating diversity</p>
        

        

        <form action="https://www.paypal.com/donate" method="post" target="_top">
<input type="hidden" name="business" value="6BHSUTET9VMCC" />
<input type="hidden" name="no_recurring" value="0" />
<input type="hidden" name="item_name" value="Help us at Fantasy Studios get started in creating a vibrant and accessible art ecosystem that empowers artists and creators!" />
<input type="hidden" name="currency_code" value="USD" />
<input type="button" style={{opacity: 0}} name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
<button className="cta-button">Donate to help us get started</button>
</form>

      <h1>About Us</h1>
      <p> We passionately believe in the power of creativity to transform lives and transcend boundaries. Our mission is to nurture, empower, and celebrate artists and creators from every corner of the world, fostering an inclusive, collaborative, and innovative community that drives positive change in the art industry.</p>
    <p>We are dedicated to providing unparalleled opportunities for our diverse network of talent to develop and showcase their unique voices, while offering tailored support to help them build thriving, sustainable careers in the art world. By leveraging our global connections and expertise, we champion cultural exchange, inspire creative growth, and promote the value of art in enriching the human experience.
</p>
<p>Together, we strive to create a vibrant and accessible global art ecosystem that empowers artists, elevates diverse perspectives, and inspires a new generation of creators to shape a more vibrant, connected, and culturally rich world.
</p>

    </div>
  );
}

export default About;